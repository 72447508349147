import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/general/Login.vue'


// Importamos la tienda
import store from '../store/index.js';
import decode from 'jwt-decode'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/',
      name: 'principal',
      component: () => import('../views/Principal.vue'),
      children: [
        {
          path: "/administracion",
          name: "administracion",
          component: () => import( '../views/administracion/Index.vue'),
          meta: {requireAuth: true},
          children: [
            {
              path: "/administracion/usuarios",
              name: "usuarios",
              component: () => import('../views/administracion/usuarios/Report.vue'),
              meta: {requireAuth: true, permiso: true, modulo: 3}
            },
            {
              path: "/administracion/usuarios/usuario/:id?",
              name: "usuarioid",
              component: () => import('../views/administracion/usuarios/Formulario.vue'),
              meta: {requireAuth: true, permiso: true, modulo: 3}
            },
            
          ]
        },
        
        
        {
          path: "/presupuestos",
          name: "presupuestos",
          component: () => import('../views/gestion/presupuestos/Report.vue'),
          meta: {requireAuth: true, permiso: true, modulo: 5}
        },
        {
          path: "/presupuestos/presupuesto/:id?",
          name: "presupuesto",
          component: () => import('../views/gestion/presupuestos/Formulario.vue'),
          meta: {requireAuth: true, permiso: true, modulo: 5}
        },
        {
          path: "/proyectos/proyectos",
          name: "proyectos",
          component: () => import('../views/gestion/proyectos/Report.vue'),
          props: true,
          meta: {requireAuth: true, permiso: true, modulo: 6}
        },
        {
          path: "/proyectos/proyectos/proyecto/:id?",
          name: "proyecto",
          component: () => import('../views/gestion/proyectos/Formulario.vue'),
          meta: {requireAuth: true, permiso: true, modulo: 6}
        },
        {
          path: "/proyectos/seguimiento",
          name: "seguimiento",
          component: () => import('../views/gestion/proyectos/Seguimiento.vue'),
          props: true,
          meta: {requireAuth: true, permiso: true, modulo: 7}
        },
        {
          path: "/proyectos/calendario",
          name: "calendario",
          component: () => import('../views/gestion/proyectos/Calendario.vue'),
          props: true,
          meta: {requireAuth: true, permiso: true, modulo: 10}
        },
        {
          path: "/facturacion",
          name: "facturacion",
          component: () => import('../views/gestion/facturacion/Report.vue'),
          props: true,
          meta: {requireAuth: true, permiso: true, modulo: 8}
        },
        {
          path: "/facturacion/factura/:id?",
          name: "factura",
          component: () => import('../views/gestion/facturacion/Factura.vue'),
          meta: {requireAuth: true, permiso: true, modulo: 8}
        },
        {
          path: "/dashboard",
          name: "dashboards",
          component: () => import( '../views/dashboard/Index.vue'),
          meta: {requireAuth: true},
          children: [
            {
              path: "/dashboard/principal",
              name: "dashboard",
              component: () => import('../views/dashboard/dashboard/Dashboard.vue'),
              meta: {requireAuth: true, permiso: true, modulo: 1}
            },
            
            
          ]
        },
        {
          path: "/perfil",
          name: "perfil",
          component: () => import('../views/general/Perfil.vue'),
          meta: {requireAuth: true},
        },
        {
          path: "/mandantes",
          name: "mandantes",
          component: () => import('../views/gestion/mandante/Report.vue'),
          meta: {requireAuth: true, permiso: true, modulo: 4},
        },
        {
          path: "/mandantes/mandante/:id?",
          name: "mandante",
          component: () => import('../views/gestion/mandante/Formulario.vue'),
          meta: {requireAuth: true, permiso: true, modulo: 4},
        },
        {
          path: "/mandantes/mandanteshijo/2/:padre?",
          name: "mandanteshijo",
          component: () => import('../views/gestion/mandante/Report.obras.vue'),
          meta: {requireAuth: true, permiso: true, modulo: 4},
        },
        {
          path: "/mandantes/mandantehijo/:padre/:id?",
          name: "mandantehijo",
          component: () => import('../views/gestion/mandante/Formulario.obras.vue'),
          meta: {requireAuth: true, permiso: true, modulo: 4},
        },
        
        
        
        
        
        
      ],
      meta: {requireAuth: true}
    }
    
  ]
});

router.beforeEach((to, from, next) => {
  
  if(to.meta.requireAuth){
    //const authUser = JSON.parse(localStorage.getItem('token'))
    if(store.state.token === ''){
      next({name: 'login'})
    }else{
      next()
    }
  }else if(to.meta.admin){
    const deco = decode(store.state.token)
    if(deco.data.role === 'ADMIN'){
      next()
    }else{
      next({name: 'principal'})
      //console.log('aca lo manda de vuelta')
    }
  }else if(to.meta.permiso){
    const deco = decode(store.state.token)
    var modulos = deco.data.mods;

    if(deco.data.role == 'ADMIN')
    { 
      next()
    }else{
      if(!modulos.includes(to.meta.modulo)){
        next({name: 'principal'})
      }
      else next()
  }


  }else if(to.meta.residente){
    const deco = decode(store.state.token)
    var modulos = deco.data.mods;

    if(deco.data.role == 'RESIDENTES' || deco.data.role == 'ADMIN' ||  deco.data.role == 'ADMINISTRACION')
    { 
      next()
    }else{
      next({name: 'principal'})
    }
  }else{
    //console.log('llega aca sin autin')
    next()
  }

})

export default router;