import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import BootstrapVue from 'bootstrap-vue'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import moment from 'moment'
import VueIziToast from 'vue-izitoast';
 
import 'izitoast/dist/css/iziToast.css';
import VueMask from 'v-mask'
Vue.use(VueMask)

Vue.use(VueIziToast);

Vue.config.productionTip = false

Vue.use(BootstrapVue)


Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY hh:mm')
  }
})
Vue.filter('fechaMongo', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD')
  }
})

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

const DEFAULT_TITLE = 'Grupo Eléctrico Ingenieria';
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
        
    });
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

